import { CoreLayoutCMSSection } from '@/components/CMS/CoreLayoutCMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/components/CMS/types'
import { breakpoints } from '@/styles/media'
import styled from 'styled-components'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'
import { CMSLayoutContextProvider } from '@/components/CMS/CMSLayoutContext'

export const RetailerHomePageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'retailer_home_page_layout'>['content']
}) => {
  const sectionAandB = (data?.body_section_a ?? []).concat(
    data?.body_section_b ?? []
  )
  return (
    <CMSLayoutContextProvider data={content_type_id}>
      <Wrapper {...getCMSWrapperProps({ content_type_id, id })}>
        {sectionAandB && <CoreLayoutCMSSection section={sectionAandB} />}
      </Wrapper>
    </CMSLayoutContextProvider>
  )
}
const Wrapper = styled.div`
  margin-top: 5rem;

  @media ${breakpoints.medium} {
    margin-top: 1rem;
  }
`
